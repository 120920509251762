import {useForm} from "react-hook-form";
import {useContext, useState} from "react";
import {postData} from "../contacts/api/fetchMessage";
import Input from "../shared/contact/Input";
import Button from "../shared/contact/Button";
import {useTranslation} from "react-i18next";
import {DataContext} from "../context/DataProvider";
import {MdKeyboardArrowDown} from "react-icons/md";
import Notification from "../shared/contact/Notification";

export default function  RegisterForm({check = true}) {
    const {t} = useTranslation();
    const {register, handleSubmit, reset, setValue, formState: {errors}} = useForm();
    const {courses} = useContext(DataContext);

    const [selectedCourses, setSelectedCourses] = useState("");
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const [showNotify, setShowNotify] = useState(false)
    const [status, setStatus] = useState(null)
    const triggerNotification = () => {
        setShowNotify(true)
        setTimeout(() => setShowNotify(false), 3000)
    }
    const onSubmit = async data => {
        console.log(data)
        try {
            const response = await postData(data,setSelectedCourses)

            triggerNotification()
            setStatus({
                status: 'success',
                message: 'Message was sent successfully',
            })

            reset()
            console.log('Data posted successfully:', response)
        } catch (error) {
            reset()
            triggerNotification()
            setStatus({
                status: 'error',
                message: error.message,
            })
            console.error('Error occurred while posting context:', error)
        }
    }

    const handleCoursesSelect = (coursesId) => {
        setSelectedCourses(coursesId);
        setValue("course", coursesId, {shouldValidate: true});
        setIsDropdownOpen(false);
    };

    return (
        <div>
            <form
                onSubmit={handleSubmit(onSubmit)}
                className={`min-w-[400px] flex flex-col gap-5 relative bg-white p-8 rounded-lg z-10 ${check ? "shadow-lg max-w-md" : ''}`}
            >
                <h2 className={`text-center text-2xl font-bold uppercase z-20 font-roboto-slab text-primaryDark`}>
                    Registration Form
                </h2>
                <div className="space-y-4">
                    <Input
                        placeholder={'Full Name'}
                        name="full_name"
                        {...register("full_name", {required: t("fullName is required")})}
                        error={errors.full_name?.message}
                    />

                    <div className="mb-4 relative">
                        <button
                            type="button"
                            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                            className="capitalize w-full flex justify-between items-center pl-5 py-2 border border-gray-300 rounded-lg text-primaryDark"
                        >
                            <p className={'capitalize'}>
                                {selectedCourses ? courses.find(cat => cat.id === selectedCourses)?.name : "Courses"}
                            </p>
                            <MdKeyboardArrowDown className='text-[18px]'/>
                        </button>

                        {isDropdownOpen && (
                            <ul className="absolute w-full bg-white border border-gray-300 rounded-lg mt-1 max-h-32 overflow-y-auto z-10">
                                {courses.map((course) => (
                                    <li
                                        key={course.id}
                                        onClick={() => handleCoursesSelect(course.id)}
                                        className="p-3 cursor-pointer hover:bg-gray-200 capitalize"
                                    >
                                        {course.name}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                    {errors.category && <p className="text-red-500 text-sm mt-1">{t(errors.category.message)}</p>}

                    <Input
                        placeholder={"Phone Number"}
                        type="tel"
                        name="phone"
                        {...register("phone", {
                            required: t("Phone number is required"),
                            pattern: {value: /^\+?\d+$/, message: t("Please enter a valid phone number")},
                        })}
                        error={errors.whatsapp?.message}
                    />

                    <Input
                        placeholder={"Email"}
                        type="email"
                        name="email"
                        {...register("email", {required: t("Email is required")})}
                        error={errors.email?.message}
                    />

                    <Input
                        placeholder={"Message"}
                        name="message"
                        {...register("message", {required: t("Message is required")})}
                        error={errors.message?.message}
                    />

                    <Button type="submit" text={t("confirm")}/>
                </div>
            </form>
            {showNotify && (
                <Notification status={status.status} message={status.message}/>
            )}
        </div>
    );
}
